import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import ScrollspyNav from 'react-scrollspy-nav';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Row, Col, Card } from 'react-bootstrap';
import { AshCharacter } from '../../../modules/ash/components/ash-character';
import { AshTrace } from '../../../modules/ash/components/ash-trace';

const AshGuidesSYPLorelle: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash-guide'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should You Pull Lorelle?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_lorelle.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should You Pull Lorelle?</h1>
          <h2>
            A dedicated guide for Lorelle that will help you decide whether to
            pull her or not.
          </h2>
          <p>
            Last updated: <strong>11/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Introduction" />
              <p>
                <strong>The core of the Corruption team</strong>, Lorelle can be
                considered a must-have if you plan on building around that
                element. She functions both as the frontline as well as an
                enabler to the rest of the team, thanks to her skill
                “Polyphony”, which provides boosts to defense and offense,
                depending on which mode Lorelle is set to.{' '}
                <strong>
                  The best part is, most of these boons work with any character
                  in the game, making Lorelle a great asset to have in your
                  account, regardless of element
                </strong>
                . At 0 dupes, she’s already a solid tank for any team, while at
                1 dupe, her buffs from “Polyphony” take effect even when she’s
                placed as backup.
              </p>
              <StaticImage
                src="../../../images/ash/pull/lorelle_1.webp"
                alt="Guides"
              />
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Review" />
              <p>
                The key to understand Lorelle’s gameplay is her “Prelude” skill.{' '}
                <strong>
                  Like other Vanguards, Lorelle can alternate between two modes:
                  Offense and Defense.
                </strong>{' '}
                In her case, both modes are tied to her passive, “Polyphony”.
              </p>
              <p>
                In <strong>Defense mode</strong>, she’s more likely to get
                targeted by enemies, gains a Block Gauge and an increase in DR.
                If her Block Gauge is not empty, when allied Echomancers take
                DMG, 30% of it is diverted to Lorelle, allowing her to protect
                her allies no matter where they are.
              </p>
              <p>
                In <strong>Offense mode</strong>, Lorelle increases allied
                Echomancer's ATK DMG by 20%. She also deals more damage herself,
                especially against Elite and Boss enemies. For this reason, try
                to keep Lorelle in this mode as much as possible until her or
                her allies start to fall below 50% HP.
              </p>
              <p>
                Lorelle's Seed Skill, “Night-light Aria”, is quite simple but
                unique. While she's casting it, she cannot act (refer to the
                screenshot below), but the effects of Polyphony are enhanced,
                she gains a Shield, and she also records all DMG Dealt by allies
                while this state lasted, unleashing 30% of the total recorded
                DMG as [True DMG] to all enemies. Try to line up all your
                offensive tools to unleash it during the burst window created by
                “Night-light Aria”. Additionally, thanks to the shield that the
                ult gives Lorelle, it is also useful to trigger skills from
                Memory Traces related to shields.
              </p>
              <p>
                <strong>
                  Finally, thanks to the versatility of her passives, Lorelle
                  can easily be slotted into any team.
                </strong>{' '}
                Also, a benefit from the Corrosion element is that it only needs
                two Echomancers of that element to be effective, allowing for a
                wide variety of combinations with other elements. Lorelle
                doesn't need any dupe, but like every other Echomancer, they
                have their utility. The first dupe is the best one, and on top
                of increasing the effects of Polyphony, it allows its passives
                to take effect even when she isn't on the field, although with
                only 50% of effectiveness. It's advised to keep her in Offense
                mode for this (that's the default if you don't deploy her at
                all). The third dupe is only worth chasing if you're focusing on
                Corrosion as one of your main teams, as it reduces enemies’
                Corrosion RES by 20% when they're affected by Polyphony.
              </p>
              <StaticImage
                src="../../../images/ash/pull/lorelle_2.webp"
                alt="Guides"
              />
              <h5>Pros & Cons</h5>
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Extremely tanky in Defense Mode, redirects part of
                          team damage to herself;
                        </li>
                        <li>
                          Great at breaking enemy shields, especially in Offense
                          mode;
                        </li>
                        <li>Can provide accelerated Corrosion Accumulation;</li>
                        <li>
                          Can also be slotted into non-Corrosion teams as a
                          great tank;
                        </li>
                        <li>
                          Works perfectly at low investment with a single copy.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Auto-Battle AI does not know how to enable Defense
                          mode, making her a much worse tank without player
                          intervention;
                        </li>
                        <li>Low damage outside dedicated corrosion teams;</li>
                        <li>
                          Can struggle to kill enemies by herself in Nexus mob
                          stages;
                        </li>
                        <li>
                          Requires some micromanagement of her Defense/Offense
                          modes for best results;
                        </li>
                        <li>No barriers.</li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Gameplay Tips" />
              <h5>General Tips</h5>
              <ul>
                <li>
                  When using Traces like “Emptiness”, you can let Lorelle's HP
                  fall below 50% HP to trigger the Trace skill buff before
                  switching to Defense mode.
                </li>
                <li>
                  Similarly, you can switch between Offense and Defense mode to
                  easily trigger any Trace skills that are affected by Block
                  Gauge.
                </li>
                <li>
                  Pay attention to what mode Lorelle is in before activating her
                  Seed Skill, as it will enhance the passives from Polyphony. It
                  can be used either to create a window of Burst damage for the
                  team, or to save them in a pinch.
                </li>
              </ul>
              <h5>Echoing Nexus Tips</h5>
              <p>
                When running the Nexus as Lorelle, it can take a while for her
                to clear mobs during Encounters. Prioritize upgrading TRM first
                in order to make these encounters easier.
              </p>
              <p>
                In the “Phantasm Realm” path, if you dodge the projectiles of
                the enemies during Encounters, the enemies stop acting and
                become a lot easier to defeat.
              </p>
              <h5>Skill Upgrade Priority</h5>
              <ul>
                <li>
                  “Prelude” is the priority here, as it affects the performance
                  and effectiveness of Polyphony for Lorelle herself. With this
                  being the central part of her kit, it's a no brainer.
                </li>
              </ul>
              <StaticImage
                src="../../../images/ash/pull/lorelle_3.webp"
                alt="Guides"
              />
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Team Recommendation" />
              <div className={`character-teams box Corrosion`}>
                <div className="team-header">
                  <p>FULL CORROSION</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="caroline"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="gina"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="lorelle"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role tank">Tank</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="freda"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                Our main team has <strong>Lorelle</strong> as Tank, increasing
                the team's survivability and damage potential, Caroline as DPS
                with some healing on the side, and <strong>Gina</strong> for
                Burst Damage and Anti-Projectile capabilities. All three provide
                Corrosion Accumulation which increases the team's damage output.
                Freda provides consistent healing and buffs.{' '}
                <strong>Aceo</strong> and <strong>Sephonis</strong> can be used
                as backups or switch in with Gina in case you don’t have her.
              </p>
              <h5>Leader: Caroline</h5>
              <p>
                <strong>Memory Traces</strong>
              </p>
              <ul>
                <li>
                  <AshTrace name="Hidden Blade" mode="data" onProfile />{' '}
                </li>
                <li>
                  <AshTrace name="Shadows Of The Alley" mode="data" onProfile />{' '}
                  or <AshTrace name="Nightmare" mode="data" onProfile /> (if
                  with Longqing) or{' '}
                  <AshTrace name="Happy Hours" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Growth" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Muscle Building" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Emptiness" mode="data" onProfile /> or{' '}
                  <AshTrace name="Behind the Curtains" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Illusion of Flowers" mode="data" onProfile />{' '}
                  or <AshTrace name="Karma" mode="data" onProfile /> if with
                  Lorelle as Leader
                </li>
              </ul>
              <div className={`character-teams box Corrosion`}>
                <div className="team-header">
                  <p>ICE WITH SPICE</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="ivana"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Main DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="begonia"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Sub DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="lorelle"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role tank">Tank</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="lydia"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Elemental Reaction</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                Better suited for [Wet Environment]s, this team is a proof of
                concept of Lorelle’s versatility and ability to enable all sorts
                of team compositions. Begonia’s ASPD buffs greatly enhance
                Ivana’s and Lydia’s performances, both heavily tied to their
                auto-attacks. With Lorelle diverting 30% of damage and Lydia
                easily triggering Electrolyzed, this team can survive just fine
                without a healer while dishing lots of damage. You can also opt
                to swap Begonia or Lydia for <strong>Sambheka</strong> to enable
                the team in non-Wet environments, and always bring a healer as
                backup in case things go south.{' '}
                <strong>
                  If you don’t feel secure, using a healer from the start and
                  treating this as a Freeze team is also a valid approach.
                </strong>
              </p>
              <h5>Leader: Ivana</h5>
              <p>
                <strong>Memory Traces</strong>
              </p>
              <ul>
                <li>
                  <AshTrace name="Shadows of the Alley" mode="data" onProfile />{' '}
                  or <AshTrace name="Happy Hours" mode="data" onProfile />{' '}
                </li>
                <li>
                  <AshTrace name="Traces in the Museum" mode="data" onProfile />{' '}
                  or{' '}
                  <AshTrace
                    name="Beneath the Deception"
                    mode="data"
                    onProfile
                  />
                </li>
                <li>
                  <AshTrace name="Emptiness" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Hello, Visier" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Behind the Curtains" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Growth" mode="data" onProfile />
                </li>
              </ul>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Team Recommendation (Beginner/Budget version)" />
              <div className={`character-teams box Corrosion`}>
                <div className="team-header">
                  <p>GOTTA GO FAST</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="begonia"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Main DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="felicio"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Sub DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="lorelle"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role tank">Tank</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="freda"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                A Beginner friendly team fully focused on getting as much ASPD
                and Cooldown reduction as possible. These four characters all
                stack buffs from ATK to CRIT to Extra DMG to the aforementioned
                ASPD and Cooldown reduction, and are all focused on auto-attack.
                That makes this team very forgiving for beginners who will still
                maintain their output even if their run out of skill uses. Aside
                from that, the team has everything covered, from block gauge
                breaking to interception of projectiles and Airborne enemies.
              </p>
              <div className={`character-teams box Corrosion`}>
                <div className="team-header">
                  <p>BUDGET EXPLOSION</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="ms-lew"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Main DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="reid"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Fire Zone</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="lorelle"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role tank">Tank</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="su-xiao"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                A team composed mainly of free characters and Memory Traces +
                Lorelle. It’s nevertheless powerful enough to carry you all the
                way through the game’s main content, and is also a very
                forgiving team for players who are learning the game. Reid can
                heal himself fully once per battle, and Su Xiao can save an ally
                from death once per battle as well (twice once she gets her
                third dupe). As you progress through the game, you can upgrade
                your team comp with higher rarity characters that will perform
                the same functions but with higher output, such as Lydia,
                Hassel, Cyros and Freda.
              </p>
              <ul>
                <li>
                  <AshTrace name="Happy Hours" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Codename Diversity" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace
                    name="Beneath the Deception"
                    mode="data"
                    onProfile
                  />{' '}
                  or{' '}
                  <AshTrace name="Into a Paper Crane" mode="data" onProfile />{' '}
                  (“Gotta go Fast” team) or{' '}
                  <AshTrace name="Deadline" mode="data" onProfile /> (“Budget
                  Explosion” team)
                </li>
                <li>
                  <AshTrace name="Behind the Curtains" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Karma" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Blows? Bonds?" mode="data" onProfile />
                </li>
              </ul>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="Future Synergies" />
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="leilani"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                <strong>Leilani</strong> comes in along with the Chapter 6
                update and replaces Gina as the Skirmisher of the Corrosion
                team. She also becomes the most common choice for team Leader
                due to her Leader passives, extremely high personal output and
                unique mechanic built into her first skill. It can activate
                secondary Corrosion Blasts on demand, increasing the potential
                of the team as a whole, and triggering Corrosion Trace skills
                more often. The only reason she's rated as A-rank in CN is due
                to being too limited to the Corrosion team.
              </p>
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="solbyrd"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                <strong>Solbyrd</strong> brings a lot to the table. He's the
                most recent addition to the Corrosion element and, unlike
                Leilani, is easy to slot into other teams thanks to his general
                utility provided. He can displace enemies and increase their
                damage taken. His own output is also very high, and the only
                reason he's not rated as S, is because he needs 3 dupes to
                properly cycle his ult, one of the main tools of his kit. If
                with 3 dupes, Solbyrd becomes a good option in general for
                Leader spot on teams who use Seed Skills often.
              </p>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h5>Jump to</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Review</a>
                </li>
                <li>
                  <a href="#section-2">Gameplay Tips</a>
                </li>
                <li>
                  <a href="#section-3">Team Recommendation</a>
                </li>
                <li>
                  <a href="#section-4">
                    Team Recommendation (Beginner/Budget version)
                  </a>
                </li>
                <li>
                  <a href="#section-5">Future Synergies</a>
                </li>
              </ul>
            </ScrollspyNav>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesSYPLorelle;

export const Head: React.FC = () => (
  <Seo
    title="Should You Pull Lorelle | Ash Echoes | Prydwen Institute"
    description="A dedicated guide for Lorelle that will help you decide whether to pull her or not."
    game="ash"
  />
);
